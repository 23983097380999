import { createContext } from 'react'

import { IUser } from 'interfaces/user'

interface ContextProps {
	isLoggedIn: boolean
	user?: IUser

	logIn: () => void
	logout: () => void
	signUp: () => void
}

export const AuthContext = createContext({} as ContextProps)

if (process.env.NODE_ENV !== 'production') {
	AuthContext.displayName = 'AuthContext'
}
