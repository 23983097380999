import React, { FC } from 'react'

import { Typography } from '@mui/material'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Stack from '@mui/material/Stack'

import { AstaMessage } from './AstaMessageContext'

interface Props {
	message: AstaMessage
	onClose: () => void
}

const AstaMessageComponent: FC<Props> = ({ message, onClose }) => {
	if (!message) return null

	const { content, title, variant } = message

	return (
		<Stack
			sx={{
				width: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				position: 'absolute',
				top: 0,
				animation: 'fadeIn 1.5s',
				zIndex: 9999
			}}
			spacing={2}
		>
			<Alert
				sx={{
					width: '30%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}
				severity={variant}
				onClose={onClose}
			>
				<AlertTitle>
					<Typography variant="h6">{title}</Typography>
				</AlertTitle>
				<Typography variant="body2">{JSON.stringify(content)}</Typography>
			</Alert>
		</Stack>
	)
}

export default AstaMessageComponent
