import 'nextra-theme-docs/style.css'

import { ReactElement, ReactNode } from 'react'

import Locales from 'components/Locales'
import { AstaMessageProvider } from 'contexts/astaMessage'
import { AuthProvider } from 'contexts/auth'
import { NextPage } from 'next'
import type { Session } from 'next-auth'
import { SessionProvider } from 'next-auth/react'
import type { AppProps } from 'next/app'
import Script from 'next/script'
import { DefaultStaticProps } from 'shared/withPageProps'
import ThemeCustomization from 'themes'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Toaster } from 'react-hot-toast'

type LayoutProps<P> = NextPage<P> & {
	getLayout?: (page: ReactElement) => ReactNode
}

interface Props<P> {
	Component: LayoutProps<P>
}

export type DefaultPageProps = DefaultStaticProps & {
	session: Session | null | undefined
}
export type OssAppProps<P extends DefaultPageProps> = AppProps<P> & Props<P>

export default function OssApp<P extends DefaultPageProps>(props: OssAppProps<P>) {
	const { Component, pageProps } = props
	const getLayout = Component.getLayout ?? ((page: any) => page)
	// Note: will not exist on 404/500 pages
	const { repository, companion } = pageProps.config?.services ?? {}

	const { analytics, auth } = pageProps.config ?? {}

	const cognitoAuthConfig = {
		clientId: auth?.cognito?.clientId,
		domain: auth?.cognito?.domain,
		redirectUrl: auth?.nextauth?.url
	}

	const queryClient = new QueryClient()

	return (
		<>
			<Script async src="https://js.stripe.com/v3/pricing-table.js" strategy="afterInteractive"></Script>
			<Script strategy="lazyOnload" src={`https://www.googletagmanager.com/gtag/js?id=${analytics?.google?.measurementId}`} />
			<Script strategy="lazyOnload" id="google-analytics">
				{`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${analytics?.google?.measurementId}', {
                    page_path: window.location.pathname,
                    });
                `}
			</Script>
			<QueryClientProvider client={queryClient}>
				<AstaMessageProvider>
					<SessionProvider session={pageProps.session} refetchInterval={0}>
						<AuthProvider repositoryUrl={repository?.url} companionUrl={companion?.url} cognito={cognitoAuthConfig}>
							<ThemeCustomization>
								<Locales>{getLayout(<Component {...pageProps} />)}</Locales>
							</ThemeCustomization>
						</AuthProvider>
					</SessionProvider>
				</AstaMessageProvider>
			</QueryClientProvider>
			<Toaster />
		</>
	)
}
