// @ts-ignore
import React, { useContext } from 'react'

import { AstaMessageContext } from './AstaMessageContext'

export const useAstaMessage = () => {
	const { addMessage } = useContext(AstaMessageContext)

	return {
		addMessage
	}
}
