import { AstaMessage } from './AstaMessageContext'
import { AstaMessageState } from './AstaMessageProvider'

type AstaMessageActionType = { type: '[AstaMessage] - Add Message'; payload: AstaMessage } | { type: '[AstaMessage] - Remove Message' }

export const astaMessageReducer = (state: AstaMessageState, action: AstaMessageActionType): AstaMessageState => {
	switch (action.type) {
		case '[AstaMessage] - Add Message':
			return {
				...state,
				message: action.payload
			}

		case '[AstaMessage] - Remove Message':
			return {
				...state,
				// @ts-ignore
				message: null
			}

		default:
			return state
	}
}
