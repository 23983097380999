// project import
import { PalettesProps } from '@ant-design/colors'
// types
import { PaletteThemeProps } from 'types/theme'

import Default from './default'

// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

const Theme = (colors: PalettesProps): PaletteThemeProps => {
	return Default(colors)
}

export default Theme
