import { createContext } from 'react'

export interface AstaMessage {
	content: string
	title: string
	variant: 'success' | 'error'
}

interface ContextProps {
	addMessage: (message: AstaMessage) => void
}

export const AstaMessageContext = createContext({} as ContextProps)
