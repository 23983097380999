import React, { FC, useReducer } from 'react'

import AstaMessageComponent from './AstaMessage'
import { AstaMessage, AstaMessageContext } from './AstaMessageContext'
import { astaMessageReducer } from './AstaMessageReducer'

export interface AstaMessageState {
	message: AstaMessage
}

const ASTA_MESSAGE_INITIAL_STATE: AstaMessageState = {
	// @ts-ignore
	message: null
}

export const AstaMessageProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
	const [state, dispatch] = useReducer(astaMessageReducer, ASTA_MESSAGE_INITIAL_STATE)

	const addMessage = (message: AstaMessage) => {
		dispatch({
			type: '[AstaMessage] - Add Message',
			payload: message
		})
	}

	const removeMessage = () => {
		dispatch({
			type: '[AstaMessage] - Remove Message'
		})
	}

	return (
		<AstaMessageContext.Provider
			value={{
				...state,
				addMessage
			}}
		>
			<AstaMessageComponent message={state.message} onClose={removeMessage} />
			{children}
		</AstaMessageContext.Provider>
	)
}
