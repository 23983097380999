import { ReactNode, useMemo } from 'react'

// material-ui
import { CssBaseline } from '@mui/material'
import { createTheme, Theme, ThemeOptions, ThemeProvider, TypographyVariantsOptions, StyledEngineProvider } from '@mui/material/styles'
// types
import { CustomShadowProps } from 'types/theme'

// project import
import Palette from './palette'
import CustomShadows from './shadows'
import Typography from './typography'

// types
type ThemeCustomizationProps = {
	mode?: 'light' | 'dark'
	children: ReactNode
}

export const fontFamily = "'Public Sans', sans-serif"
export const presetColor = 'default'
export const themeDirection = 'ltr'

export default function ThemeCustomization({ children, mode = 'light' }: ThemeCustomizationProps) {
	const theme: Theme = useMemo<Theme>(() => Palette(mode, presetColor), [mode, presetColor])

	const themeTypography: TypographyVariantsOptions = useMemo<TypographyVariantsOptions>(
		() => Typography(),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[mode, fontFamily]
	)
	const themeCustomShadows: CustomShadowProps = useMemo<CustomShadowProps>(() => CustomShadows(theme), [theme])

	const themeOptions: ThemeOptions = useMemo(
		() => ({
			breakpoints: {
				values: {
					xs: 0,
					xss: 425,
					sm: 768,
					md: 1024,
					lg: 1266,
					xl: 1536
				}
			},
			direction: themeDirection,
			mixins: {
				toolbar: {
					minHeight: 60,
					paddingTop: 8,
					paddingBottom: 8
				}
			},
			palette: theme.palette,
			customShadows: themeCustomShadows,
			typography: themeTypography
		}),
		[themeDirection, theme, themeTypography, themeCustomShadows]
	)

	const themes: Theme = createTheme(themeOptions)

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={themes}>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</StyledEngineProvider>
	)
}
