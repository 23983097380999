// material-ui
// third-party
import { PalettesProps, presetPalettes, presetDarkPalettes } from '@ant-design/colors'
import { PaletteMode } from '@mui/material'
import { createTheme } from '@mui/material/styles'
// types
import { PaletteThemeProps } from 'types/theme'

// project import
import ThemeOption from './theme'

declare module '@mui/material/styles' {
	interface Palette {
		custom: Palette['primary']
	}
	interface PaletteOptions {
		custom?: PaletteOptions['primary']
	}
}

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = (mode: PaletteMode, presetColor: string) => {
	const colors: PalettesProps = mode === 'light' ? presetPalettes : presetDarkPalettes

	const greyPrimary = [
		'#ffffff',
		'#fafafa',
		'#f5f5f5',
		'#f0f0f0',
		'#d9d9d9',
		'#bfbfbf',
		'#8c8c8c',
		'#595959',
		'#262626',
		'#141414',
		'#000000'
	]
	const greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f']
	const greyConstant = ['#fafafb', '#e6ebf1']

	colors.grey = [...greyPrimary, ...greyAscent, ...greyConstant]

	const paletteColor: PaletteThemeProps = ThemeOption(colors)

	return createTheme({
		palette: {
			mode,
			common: {
				black: '#000',
				white: '#fff'
			},
			...paletteColor,
			text: {
				primary: paletteColor.grey[700],
				secondary: paletteColor.grey[500],
				disabled: paletteColor.grey[400]
			},
			action: {
				disabled: paletteColor.grey[300]
			},
			divider: paletteColor.grey[200],
			background: {
				paper: paletteColor.grey[50],
				default: paletteColor.grey.A100
			},
			custom: {
				main: '#181A1B',
				light: '#E8E6E3',
				dark: '#596064',
				contrastText: '#ffffff'
			}
		}
	})
}

export default Palette
