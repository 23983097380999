import { ReactNode, useEffect, useState } from 'react'

// third-party
import { IntlProvider, MessageFormatElement } from 'react-intl'

// project import
// import { I18n } from 'types/config';

// load locales files
const loadLocaleData = () => {
	return import('utils/locales/en.json')
}

// ==============================|| LOCALIZATION ||============================== //

interface Props {
	children: ReactNode
}

const Locales = ({ children }: Props) => {
	const [messages, setMessages] = useState<Record<string, string> | Record<string, MessageFormatElement[]> | undefined>()

	useEffect(() => {
		loadLocaleData().then((d: { default: Record<string, string> | Record<string, MessageFormatElement[]> | undefined }) => {
			setMessages(d.default)
		})
	}, [])

	return (
		<>
			{messages && (
				<IntlProvider locale={'en'} defaultLocale="en" messages={messages}>
					{children}
				</IntlProvider>
			)}
		</>
	)
}

export default Locales
